<template>
  <div class="companies">
    <div class="companies__title">
      <PageTitle :text="userRole === 'manager' ? 'О компании' : 'Компании'" />
      <div class="companies__actions">
        <div
          v-if="currentTariff && currentCompanyOfUser"
          class="companies__tariff"
        >
          Тариф "{{ currentTariff.name }}"
          <router-link to="/tariffs" class="el-button">
            Сменить тариф
          </router-link>
        </div>
        <el-button
          v-if="!currentCompanyOfUser"
          type="primary el-button--stretch"
          @click="addCompany"
        >
          <span class="hide-xs">+ </span>Добавить
          <span class="hide-xs">компанию</span>
        </el-button>
      </div>
    </div>
    <div v-if="companiesList && companiesList.length" class="companies__table">
      <el-table
        :data="companiesList"
        :row-class-name="tableRowClassName"
        @header-click="sortData"
      >
        <el-table-column
          :index="0"
          :label-class-name="
            typeSort === 'title' ? 'sortable ' + sortOrder : ''
          "
        >
          <template slot="header">
            Название
            <svg
              v-if="!currentCompanyOfUser"
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
              <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
            </svg>
          </template>
          <template slot-scope="scope">
            <div class="companies__table-item">
              <span class="companies__table-item--bold">
                {{ scope.row.title }}
              </span>
              <br />
              <span class="companies__address">
                {{ scope.row.address }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :index="1"
          :label-class-name="
            typeSort === 'contact_fio' ? 'sortable ' + sortOrder : ''
          "
        >
          <template slot="header">
            Контактное лицо
            <svg
              v-if="!currentCompanyOfUser"
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
              <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
            </svg>
          </template>
          <template slot-scope="scope">
            <div class="companies__table-item hide-sm hide-xs">
              <span class="companies__table-item--bold">
                {{ scope.row.contact_fio }}
              </span>
              <br />
              <span>
                {{ scope.row.contact_phone }}
              </span>
            </div>
            <div class="companies__table-item hide-lg hide-md">
              <a :href="'tel:' + scope.row.contact_phone">{{
                scope.row.contact_phone
              }}</a>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :index="2"
          prop="description"
          label="Профиль деятельности"
        >
        </el-table-column>
        <el-table-column :index="3" width="200">
          <template slot="header">
            Проекты <span class="table-th--subtitle">Активны/Завершены</span>
          </template>
          <template slot-scope="scope">
            <div class="companies__table-item">
              <span class="companies__table-item--bold">
                {{ scope.row.active_project_count }} /
              </span>
              <span>
                {{ scope.row.complete_project_count }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :index="4"
          prop="worker_count"
          width="200"
          :label-class-name="
            typeSort === 'worker_count' ? 'sortable ' + sortOrder : ''
          "
        >
          <template slot="header">
            Работники
            <svg
              v-if="!currentCompanyOfUser"
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
              <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
            </svg>
          </template>
        </el-table-column>
        <el-table-column :index="5" width="50">
          <template slot-scope="scope">
            <div class="more-options hide-lg hide-md">
              <span
                @click="showDialogOptions(scope.row)"
                class="el-dropdown-link"
              >
                <img src="@/assets/icons/icon-option.svg" />
              </span>
            </div>
            <el-dropdown
              class="hide-sm hide-xs"
              trigger="click"
              @command="handleCommand"
            >
              <span class="el-dropdown-link">
                <img src="@/assets/icons/icon-option.svg" />
              </span>
              <el-dropdown-menu slot="dropdown" class="companies__options">
                <el-dropdown-item :command="{ name: 'more', id: scope.row }">
                  <img src="@/assets/icons/icon-info.svg" />
                  Подробнее
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ name: 'project', id: scope.row.id }"
                >
                  <img src="@/assets/icons/icon-project-black.svg" />
                  К проектам
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="userRole === 'admin'"
                  :command="{
                    name: 'block',
                    id: scope.row.id,
                    status: scope.row.is_blocked
                  }"
                >
                  <img
                    v-if="scope.row.is_blocked"
                    src="@/assets/icons/icon-unblock.svg"
                  />
                  <img v-else src="@/assets/icons/icon-block-back.svg" />
                  {{
                    scope.row.is_blocked ? "Разблокировать" : "Заблокировать"
                  }}
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ name: 'delete', id: scope.row.id }"
                  class="removed"
                >
                  <img src="@/assets/icons/icon-removed-red.svg" />
                  Удалить
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="Действия" :visible.sync="dialogShow" width="288px">
        <el-dropdown-item
          @click.native="
            dialogShow = false;
            handleCommand({ name: 'more', id: dialogData });
          "
        >
          <img src="@/assets/icons/icon-info.svg" />
          Подробнее
        </el-dropdown-item>
        <el-dropdown-item
          @click.native="
            dialogShow = false;
            handleCommand({ name: 'project', id: dialogData.id });
          "
        >
          <img src="@/assets/icons/icon-project-black.svg" />
          К проектам
        </el-dropdown-item>
        <el-dropdown-item
          v-if="userRole === 'admin'"
          @click.native="
            dialogShow = false;
            handleCommand({
              name: 'block',
              id: dialogData.id,
              status: dialogData.is_blocked
            });
          "
        >
          <img
            v-if="dialogData.is_blocked"
            src="@/assets/icons/icon-unblock.svg"
          />
          <img v-else src="@/assets/icons/icon-block-back.svg" />
          {{ dialogData.is_blocked ? "Разблокировать" : "Заблокировать" }}
        </el-dropdown-item>
        <el-dropdown-item
          v-if="userRole === 'admin'"
          @click.native="
            dialogShow = false;
            handleCommand({ name: 'delete', id: dialogData.id });
          "
          class="removed"
        >
          <img src="@/assets/icons/icon-removed-red.svg" />
          Удалить
        </el-dropdown-item>
      </el-dialog>
    </div>
    <div class="companies__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";
import Pagination from "../components/blocks/Pagination";

export default {
  name: "Companies",
  components: {
    PageTitle,
    Pagination
  },
  data() {
    return {
      typeSort: "title",
      ascOrder: true,
      page: 1,
      pageCount: 8,
      dialogData: {},
      dialogShow: false
    };
  },
  computed: {
    currentCompany() {
      return this.$store.state.Option.detailCompany;
    },
    companiesList() {
      let list = [];
      if (this.currentCompanyOfUser) {
        list = [this.$store.state.Auth.user.company];
      } else {
        list = this.$store.state.Company.companiesList?.map(item => ({
          ...item,
          visibleMoreOptions: false
        }));
      }
      return list;
    },
    pagination() {
      return this.$store.state.Company.pagination;
    },
    successCreate() {
      return this.$store.state.Company.successCreate;
    },
    sortOrder() {
      let order = "asc";
      if (!this.ascOrder) order = "desc";
      return order;
    },
    currentCompanyOfUser() {
      return this.$store.state.Auth.currentCompanyOfUser;
    },
    ready() {
      return this.$store.state.Auth.ready;
    },
    userRole() {
      return this.$store.state.Auth.userRole;
    },
    currentTariff() {
      return this.$store.getters["Tariffs/currentTariff"];
    }
  },
  watch: {
    successCreate(val) {
      if (val) {
        if (!this.currentCompanyOfUser) {
          this.getCompanies();
          this.$store.commit("Company/setData", {
            label: "successCreate",
            data: false
          });
        } else {
          this.$store.dispatch("Auth/getUser");
        }
      }
    },
    ready(val) {
      if (val) {
        if (!this.currentCompanyOfUser) {
          this.getCompanies();
        }
      }
    }
  },
  destroyed() {
    this.hideCompany();
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.is_blocked) {
        return "blocked-row";
      }
      return "";
    },
    addCompany() {
      this.$store.commit("Option/setData", {
        label: "detailCompany",
        data: null
      });
      this.$store.commit("Option/setData", {
        label: "currentOption",
        data: "CompanyCard"
      });
    },
    getCompanies() {
      this.$store.dispatch("Company/getCompanies", {
        page: this.page,
        pageCount: this.pageCount,
        sortField: this.typeSort ? this.typeSort : null,
        sortBy: this.typeSort ? this.sortOrder : null
      });
    },
    changePage(page, count) {
      this.page = page;
      this.pageCount = count;
      this.getCompanies();
    },
    handleCommand(command) {
      switch (command.name) {
        case "delete":
          this.removeCompany(command.id);
          break;
        case "block":
          this.blockCompany(command.id, command.status);
          break;
        case "more":
          this.showCompany(command.id);
          break;
        case "project":
          this.$router.push({ path: "/projects?company=" + command.id });
      }
    },
    logout() {
      this.$store.dispatch("Auth/logout").then(() => {
        this.$store.commit("Auth/resetState");
        this.$store.commit("Company/resetState");
        this.$store.commit("Employee/resetState");
        this.$store.commit("Professions/resetState");
        this.$store.commit("Projects/resetState");
        this.$store.commit("Tariffs/resetState");
        this.$router.push({ name: "Login" });
      });
    },
    removeCompany(id) {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}">
        <div>
          <div class="remove-message__title">
            Удаление компании
          </div>
          <div class="remove-message__text">
            Вы уверены, что хотите удалить компанию?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Удалить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        this.$store
          .dispatch("Company/deleteCompany", id)
          .then(() => {
            this.getCompanies();
            this.$notify({
              title: "Успех",
              message: "Компания удалена",
              type: "success"
            });
            if (this.userRole !== "admin") {
              this.logout();
            }
          })
          .catch(err => {
            console.log(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    },
    blockCompany(id, status) {
      const data = { is_blocked: status === 0 ? 1 : 0 };
      this.$store
        .dispatch("Company/blockCompany", { id, data })
        .then(() => {
          this.getCompanies();
          this.$notify({
            title: "Успех",
            message: status
              ? "Компания разблокирована"
              : "Компания заблокирована",
            type: "success"
          });
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    showCompany(company) {
      company.isChangingPassword = false;
      this.$store.commit("Option/setData", {
        label: "detailCompany",
        data: company
      });
      this.$store.commit("Option/setData", {
        label: "currentOption",
        data: "CompanyCard"
      });
    },
    sortData(row) {
      if (this.currentCompanyOfUser) {
        return false;
      }
      switch (row.index) {
        case 0:
          this.typeSort === "title"
            ? (this.ascOrder = !this.ascOrder)
            : (this.ascOrder = true);
          this.typeSort = "title";
          break;
        case 1:
          this.typeSort === "contact_fio"
            ? (this.ascOrder = !this.ascOrder)
            : (this.ascOrder = true);
          this.typeSort = "contact_fio";
          break;
        case 4:
          this.typeSort === "worker_count"
            ? (this.ascOrder = !this.ascOrder)
            : (this.ascOrder = true);
          this.typeSort = "worker_count";
          break;
      }
      this.getCompanies();
    },
    hideCompany() {
      this.$store.commit("Option/closeOption");
    },
    showDialogOptions(data) {
      this.dialogData = data;
      this.dialogShow = true;
    }
  },
  created() {
    if (this.ready) {
      if (!this.currentCompanyOfUser) {
        this.getCompanies();
      }
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.companies {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    .el-button {
      width: 20.2rem;
    }
  }

  &__actions {
    display: flex;
    gap: 16px;
  }

  &__tariff {
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 700;
    margin-left: 12px;

    .el-button {
      margin-left: 12px;
      text-decoration: none;
    }
  }

  &__table {
    .cell {
      width: 80%;
    }

    .el-table th {
      height: 5.8rem;
      padding: 0;
    }

    &-item {
      &--bold {
        font-weight: 600;
        color: $text;
      }
    }
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .companies {
    .companies__table {
      margin: 0 -16px;

      .cell {
        width: 100%;
      }
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }

    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        &:nth-child(1),
        &:nth-child(4) {
          border-radius: 0;
          width: calc(50% - 12px);
          margin-top: 16px;
          text-align: left;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }
        &:nth-child(4) {
          width: calc(50% + 12px);

          .cell {
            padding-left: 0;
          }
        }

        &:last-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5) {
          display: none;
        }

        svg {
          display: none;
        }
      }

      .el-select {
        width: 100%;
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
      }
      td {
        position: static;
        border-radius: 0;
        padding: 0;
        border: none;
        text-align: left;
        font-size: 12px;
        line-height: 16px;

        &:nth-child(1) {
          width: calc(50% - 12px);
          order: -40;
          font-size: 14px;
          line-height: 20px;
        }

        &:nth-child(4) {
          width: calc(50% - 12px);
          order: -35;
        }

        &:nth-child(6) {
          width: 24px;
          order: -30;
        }

        &:nth-child(3) {
          width: 100%;
          order: -25;
        }

        &:nth-child(2) {
          width: 100%;
          order: -20;
        }

        &:nth-child(5) {
          display: none;
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }
    }
  }
  .companies__table-actions {
    padding: 0;
  }
  .companies__address {
    font-size: 12px;
  }
}

@media (max-width: $screen-xs-max) {
  .companies__title {
    .el-button {
      font-weight: 600;
      line-height: 20px;
      color: #1199f0;
      padding: 0;
      background-image: none;
      border-bottom: 1px solid;
      display: flex;
      border-radius: 0;
      width: auto;
    }
  }
}
</style>
