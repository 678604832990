var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "companies"
  }, [_c('div', {
    staticClass: "companies__title"
  }, [_c('PageTitle', {
    attrs: {
      "text": _vm.userRole === 'manager' ? 'О компании' : 'Компании'
    }
  }), _c('div', {
    staticClass: "companies__actions"
  }, [_vm.currentTariff && _vm.currentCompanyOfUser ? _c('div', {
    staticClass: "companies__tariff"
  }, [_vm._v(" Тариф \"" + _vm._s(_vm.currentTariff.name) + "\" "), _c('router-link', {
    staticClass: "el-button",
    attrs: {
      "to": "/tariffs"
    }
  }, [_vm._v(" Сменить тариф ")])], 1) : _vm._e(), !_vm.currentCompanyOfUser ? _c('el-button', {
    attrs: {
      "type": "primary el-button--stretch"
    },
    on: {
      "click": _vm.addCompany
    }
  }, [_c('span', {
    staticClass: "hide-xs"
  }, [_vm._v("+ ")]), _vm._v("Добавить "), _c('span', {
    staticClass: "hide-xs"
  }, [_vm._v("компанию")])]) : _vm._e()], 1)], 1), _vm.companiesList && _vm.companiesList.length ? _c('div', {
    staticClass: "companies__table"
  }, [_c('el-table', {
    attrs: {
      "data": _vm.companiesList,
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "header-click": _vm.sortData
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0,
      "label-class-name": _vm.typeSort === 'title' ? 'sortable ' + _vm.sortOrder : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "companies__table-item"
        }, [_c('span', {
          staticClass: "companies__table-item--bold"
        }, [_vm._v(" " + _vm._s(scope.row.title) + " ")]), _c('br'), _c('span', {
          staticClass: "companies__address"
        }, [_vm._v(" " + _vm._s(scope.row.address) + " ")])])];
      }
    }], null, false, 1131326939)
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Название "), !_vm.currentCompanyOfUser ? _c('svg', {
    attrs: {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 5H0L3 9L6 5H3Z",
      "fill": "#A2A7BE"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 4L6 4L3 0L0 4L3 4Z",
      "fill": "#A2A7BE"
    }
  })]) : _vm._e()])], 2), _c('el-table-column', {
    attrs: {
      "index": 1,
      "label-class-name": _vm.typeSort === 'contact_fio' ? 'sortable ' + _vm.sortOrder : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "companies__table-item hide-sm hide-xs"
        }, [_c('span', {
          staticClass: "companies__table-item--bold"
        }, [_vm._v(" " + _vm._s(scope.row.contact_fio) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(scope.row.contact_phone) + " ")])]), _c('div', {
          staticClass: "companies__table-item hide-lg hide-md"
        }, [_c('a', {
          attrs: {
            "href": 'tel:' + scope.row.contact_phone
          }
        }, [_vm._v(_vm._s(scope.row.contact_phone))])])];
      }
    }], null, false, 1283498972)
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Контактное лицо "), !_vm.currentCompanyOfUser ? _c('svg', {
    attrs: {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 5H0L3 9L6 5H3Z",
      "fill": "#A2A7BE"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 4L6 4L3 0L0 4L3 4Z",
      "fill": "#A2A7BE"
    }
  })]) : _vm._e()])], 2), _c('el-table-column', {
    attrs: {
      "index": 2,
      "prop": "description",
      "label": "Профиль деятельности"
    }
  }), _c('el-table-column', {
    attrs: {
      "index": 3,
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "companies__table-item"
        }, [_c('span', {
          staticClass: "companies__table-item--bold"
        }, [_vm._v(" " + _vm._s(scope.row.active_project_count) + " / ")]), _c('span', [_vm._v(" " + _vm._s(scope.row.complete_project_count) + " ")])])];
      }
    }], null, false, 364774519)
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Проекты "), _c('span', {
    staticClass: "table-th--subtitle"
  }, [_vm._v("Активны/Завершены")])])], 2), _c('el-table-column', {
    attrs: {
      "index": 4,
      "prop": "worker_count",
      "width": "200",
      "label-class-name": _vm.typeSort === 'worker_count' ? 'sortable ' + _vm.sortOrder : ''
    }
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Работники "), !_vm.currentCompanyOfUser ? _c('svg', {
    attrs: {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 5H0L3 9L6 5H3Z",
      "fill": "#A2A7BE"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 4L6 4L3 0L0 4L3 4Z",
      "fill": "#A2A7BE"
    }
  })]) : _vm._e()])], 2), _c('el-table-column', {
    attrs: {
      "index": 5,
      "width": "50"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "more-options hide-lg hide-md"
        }, [_c('span', {
          staticClass: "el-dropdown-link",
          on: {
            "click": function click($event) {
              return _vm.showDialogOptions(scope.row);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg")
          }
        })])]), _c('el-dropdown', {
          staticClass: "hide-sm hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg")
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "companies__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'more',
              id: scope.row
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg")
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'project',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-project-black.svg")
          }
        }), _vm._v(" К проектам ")]), _vm.userRole === 'admin' ? _c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'block',
              id: scope.row.id,
              status: scope.row.is_blocked
            }
          }
        }, [scope.row.is_blocked ? _c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-unblock.svg")
          }
        }) : _c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-block-back.svg")
          }
        }), _vm._v(" " + _vm._s(scope.row.is_blocked ? "Разблокировать" : "Заблокировать") + " ")]) : _vm._e(), _c('el-dropdown-item', {
          staticClass: "removed",
          attrs: {
            "command": {
              name: 'delete',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg")
          }
        }), _vm._v(" Удалить ")])], 1)], 1)];
      }
    }], null, false, 61800326)
  })], 1), _c('el-dialog', {
    attrs: {
      "title": "Действия",
      "visible": _vm.dialogShow,
      "width": "288px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogShow = $event;
      }
    }
  }, [_c('el-dropdown-item', {
    nativeOn: {
      "click": function click($event) {
        _vm.dialogShow = false;

        _vm.handleCommand({
          name: 'more',
          id: _vm.dialogData
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-info.svg")
    }
  }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
    nativeOn: {
      "click": function click($event) {
        _vm.dialogShow = false;

        _vm.handleCommand({
          name: 'project',
          id: _vm.dialogData.id
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-project-black.svg")
    }
  }), _vm._v(" К проектам ")]), _vm.userRole === 'admin' ? _c('el-dropdown-item', {
    nativeOn: {
      "click": function click($event) {
        _vm.dialogShow = false;

        _vm.handleCommand({
          name: 'block',
          id: _vm.dialogData.id,
          status: _vm.dialogData.is_blocked
        });
      }
    }
  }, [_vm.dialogData.is_blocked ? _c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-unblock.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-block-back.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.dialogData.is_blocked ? "Разблокировать" : "Заблокировать") + " ")]) : _vm._e(), _vm.userRole === 'admin' ? _c('el-dropdown-item', {
    staticClass: "removed",
    nativeOn: {
      "click": function click($event) {
        _vm.dialogShow = false;

        _vm.handleCommand({
          name: 'delete',
          id: _vm.dialogData.id
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-removed-red.svg")
    }
  }), _vm._v(" Удалить ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "companies__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }